.line {
  text-align: justify;
  position: relative;
}

.line:before {
  content: '';
  width: 100%;
  border: 1px solid var(--primary-color);
  position: absolute;
  top: 1em;
  margin-top: -6px;
  z-index: -1;
}

.tracking-point {
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.7em;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  background: white;
  display: inline-block;
  color: white;
  font-size: 1em;

  &.active {
    background: #98d5e9;
    border-color: #98d5e9;
  }
}

.tracking-check {
  &::after {
    left: 7px;
  }
}

span.marker-text {
  color: black;
  font-size: 12px;
  line-height: 16px;
  width: 70px;
  display: block;
  margin-left: -21px;
  margin-top: 2px;
}
