@import 'mixins';

.footer-row {
  @include toRem(height, 177px);
}

.footer-text {
  color: #ffffff;
  @include toRem(font-size, 12px);
}

.footer-link {
  @extend .footer-text;

  margin-right: 1rem;

  &:hover {
    color: #ffffff;
  }
}
