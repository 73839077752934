@import 'mixins';

.dropdown-box {
  border: solid #d8d8d8;
  border-width: 1px;

  &-button {
    background-color: white;
    border: none;
  }
}

.product {
  @include box-shadow(0 0 4px #ccc);
  @include toRem(font-size, 16px);

  .btn {
    background-color: white;
    border: none;
    color: var(--primary-color);

    &:active,
    &:focus {
      background-color: white !important;
      border: none !important;
      color: #002b44 !important;
      box-shadow: none !important;
    }
  }
}

.checkbox {
  background-color: white;
  border: 1px solid var(--checkbox-color);
  left: 0;
  top: 0;
  border-radius: 50%;
  height: 26px;
  position: absolute;
  width: 26px;
}

.checkbox-selected {
  background-color: var(--checkbox-color);
  &:after {
    @include transform(rotate(45deg));
    display: block;
    border: solid white;
    border-width: 0 2px 2px 0;
    content: '';
    height: 13px;
    left: 8px;
    position: absolute;
    top: 4px;
    width: 8px;
  }
}

.checkbox-not-selected {
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    background-color: var(--checkbox-color);
    height: 2px;
    margin-top: -5px;
    top: 66%;
    left: 5px;
    right: 5px;
  }
  &:before {
    content: ' ';
    position: absolute;
    display: block;
    background-color: var(--checkbox-color);
    width: 2px;
    margin-left: -5px;
    left: 67%;
    top: 5px;
    bottom: 5px;
  }
}
