@import 'custom';
@import './common/styles/mixins';

@font-face {
  font-family: 'OpenSans';
  src: local('OpenSans'),
    url(./common/fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 900;
  src: local('OpenSans'),
    url(./common/fonts/OpenSans-Bold.ttf) format('truetype');
}

.App {
  color: var(--primary-color);
  min-height: 100vh;
  font-family: OpenSans;
  @include toRem(font-size, 15px);

  &-footer {
    background-color: var(--background-color);
  }

  &-header {
    background-color: var(--background-color);
    color: white;
    @include toRem(height, 62px);
  }

  &-link {
    color: var(--primary-color);
    text-decoration: underline;
    @include toRem(font-size, 16px);
  }

  &-logo {
    pointer-events: none;
    @include toRem(height, 62px);
  }
}

h1,
h2 {
  font-weight: bold;
  @include toRem(font-size, 22px);
}

.btn {
  border-radius: 0;

  &-link {
    background-color: #ffffff;
    border: 0;

    &:hover {
      background-color: #ffffff;
    }
  }
}

.ordernumber {
  &-small {
    @include toRem(font-size, 16px);
  }

  &-big {
    @include toRem(font-size, 22px);
  }
}

.beige-box {
  background-color: #f4f2f0;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;

  &-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.error {
  color: red;
}

.return-summary {
  @include toRem(font-size, 16px);
}

.logo-prisma {
  @include toRem(max-height, 40px);
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--bootstrap-primary-color);
  border-color: var(--bootstrap-primary-color);
}

.prisma {
  :not(.product-button).btn-primary:hover,
  :not(.product-button).btn-primary:active,
  :not(.product-button).btn-primary:focus {
    background-color: darken(#e5007d, 5%) !important;
    border-color: #e5007d !important;
  }
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: OpenSans;
}
